<template>
  <v-dialog
    v-model="dialog"
    scrollable
    max-width="600px"
    @click:outside="closeForm()"
  >
    <v-form @submit.prevent="saveForm" method="post" id="main-details-form">
      <v-card>
        <v-card-title class="headline">Edit Main Details</v-card-title>
        <v-divider></v-divider>
        <v-card-text class="pt-5">
          <v-text-field
            label="First Name *"
            v-model="fields.first_name"
            outlined
            :error="errors.hasOwnProperty('first_name')"
            :error-messages="errors['first_name']"
          ></v-text-field>
          <v-text-field
            label="Last Name *"
            v-model="fields.last_name"
            outlined
            :error="errors.hasOwnProperty('last_name')"
            :error-messages="errors['last_name']"
          ></v-text-field>
          <v-text-field
            label="Email *"
            v-model="fields.email"
            outlined
            :error="errors.hasOwnProperty('email')"
            :error-messages="errors['email']"
          ></v-text-field>
          <v-text-field
            label="Location"
            v-model="fields.location"
            outlined
            :error="errors.hasOwnProperty('location')"
            :error-messages="errors['location']"
          ></v-text-field>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="secondary" text @click="closeForm()">Close</v-btn>
          <v-btn color="accent" text type="submit" :loading="loading"
            >Save</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
export default {
  data() {
    return {
      dialog: false,
      loading: false,
      errors: {},
      fields: {
        update_type: "details",
        first_name: null,
        last_name: null,
        email: null,
        location: null,
      },
    };
  },

  computed: {
    member() {
      return this.$store.state.flightmode.members["member"];
    },
  },

  mounted() {
    this.fields.first_name = this.member.first_name;
    this.fields.last_name = this.member.last_name;
    this.fields.email = this.member.email;
    this.fields.location = this.member.location;
  },

  methods: {
    openForm() {
      this.dialog = true;
    },

    saveForm() {
      this.loading = true;
      this.errors = {};

      this.$store
        .dispatch("flightmode/members/saveMember", {
          appId: this.$route.params.id,
          memberId: this.$route.params.memberId,
          fields: this.fields,
          isEditing: true,
        })
        .then(() => this.closeForm())
        .catch((err) => {
          this.errors = err.response.data.errors;
        });
    },

    closeForm() {
      this.dialog = false;
      this.errors = {};
      this.loading = false;
    },
  },
};
</script>