<template>
  <div>
    <v-row align="center">
      <v-col cols="auto" class="pr-12">
        <h2>{{ pluralString(member.first_name) }} Details</h2>
      </v-col>
      <v-col>
        <v-row align="center" justify="end" no-gutters>
          <v-btn small depressed dark @click="$refs.mainDetailsForm.openForm()">
            <v-icon left>mdi-pencil</v-icon>Edit
          </v-btn>
        </v-row>
      </v-col>
    </v-row>
    <v-sheet outlined>
      <v-simple-table>
        <tbody>
          <tr>
            <th>First Name</th>
            <td>{{ member.first_name }}</td>
          </tr>
          <tr>
            <th>Last Name</th>
            <td>{{ member.last_name }}</td>
          </tr>
          <tr>
            <th>Email</th>
            <td>{{ member.email }}</td>
          </tr>
          <tr>
            <th>Last Login</th>
            <td>12th Oct 2020 @ 12:34</td>
          </tr>
          <tr>
            <th>Member Since</th>
            <td>{{ member.formatted_dates.created_at }}</td>
          </tr>
          <tr>
            <th>Public Profile</th>
            <td>
              <v-switch
                inset
                v-model="isPublic"
                :label="isPublicLabel"
                :loading="isPublicLoading"
                color="green"
              ></v-switch>
            </td>
          </tr>
          <tr>
            <th>Location</th>
            <td>{{ member.location }}</td>
          </tr>
        </tbody>
      </v-simple-table>
    </v-sheet>
    <MainDetailsForm ref="mainDetailsForm" />
  </div>
</template>

<script>
import MainDetailsForm from "./components/MainDetailsForm";

export default {
  props: {
    member: {
      type: Object,
      required: true,
    },
  },

  components: {
    MainDetailsForm,
  },

  data() {
    return {
      isPublicLoading: false,
      isPublicLabel: null,
    };
  },

  created() {
    this.isPublicLabel = this.isPublic ? "Yes" : "No";
  },

  computed: {
    isPublic: {
      get: function () {
        return !!parseInt(this.member.is_public);
      },
      set: function (newValue) {
        this.isPublicLoading = true;
        this.isPublicLabel = newValue ? "Yes" : "No";

        this.$store
          .dispatch("flightmode/members/saveMember", {
            appId: this.$route.params.id,
            memberId: this.$route.params.memberId,
            fields: {
              update_type: "is_public",
              is_public: newValue,
            },
            isEditing: true,
          })
          .then(() => {
            this.isPublicLoading = false;
          })
          .catch(() => {
            this.isPublicLoading = false;
          });
      },
    },
  },
};
</script>